import logo from './logo.svg';
import './App.css';
import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

import { GlobalProvider } from "./context";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login'))
const QrView = React.lazy(() => import('./pages/QR/QrView'))


function App() {
  return (
    <HashRouter>
      <GlobalProvider>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route path="QrView">
             <Route path=":id" element={<QrView />} />
            </Route> 
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
        </GlobalProvider>
      </HashRouter>
  );
}

export default App;
