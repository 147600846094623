import React, { createContext, useState, useEffect } from 'react'
import Cookies from 'universal-cookie';
import {
  BrowserRouter as Router,
  useParams, useHistory
} from "react-router-dom";

export const GlobalContext = createContext();
export const GlobalProvider = (props) => {

  const [stateUser, setstateUser] = useState("");

  const [stateAlertTyp, setstateAlertTyp] = useState("");
  const [stateAlertMessage, setstateAlertMessage] = useState("");



  const cookies = new Cookies();
  //const history = useHistory();

  if (cookies.get('user') != undefined && cookies.get('user') != "" && stateUser == "") {
    setstateUser(cookies.get('user'));

  } else if (stateUser != "") {
    cookies.set('user', stateUser, { path: '/' });
  }

  //User Degısımı kontrolu
  useEffect(() => {
    if (stateUser != "") {
      cookies.set('user', stateUser, { path: '/' });
    } else if (stateUser == "") {
      //cookies.remove('user', { path: '/' });
    }
  }, [stateUser]);


  //Alert Kontrol
  useEffect(() => {
    if (stateAlertMessage != "") {
      setTimeout(() => alertClose(), 1000);
    }
  }, [stateAlertMessage]);


  function alertClose() {
    setstateAlertTyp('');
    setstateAlertMessage('');

  }

  return (
    <GlobalContext.Provider value={{ stateUser: stateUser, setstateUser, stateAlertTyp: stateAlertTyp, setstateAlertTyp, stateAlertMessage: stateAlertMessage, setstateAlertMessage }}>
      {props.children}
    </GlobalContext.Provider>

  )
};